import bank from "/public/bank.svg";
import person from "/public/person.svg";
import fingerprint from "/public/fingerprint.svg";
import verticalDivider from "/public/verticalDivider.svg";
import shield from "/public/shield.svg";
import leftCurveArrow from "/public/left_curve_arrow.svg";
import rightCurveArrow from "/public/right_curve_arrow.svg";
import questionMark from "/public/question_mark.svg";
import brandLogo from "/public/logo.svg";
import homeLocation from "/public/home_location_Icon.svg";
import infoIcon from "/public/info_icon.svg";
import appreciation from "/public/appreciation.svg";
import downArrow from "/public/arrow_down.svg";
import upArrow from "/public/arrow_up.svg";
import goldCoins from "/public/goldcard.svg";
import whiteBank from "/public/ic_select_bank.svg";
import bankDivider from "/public/bankDivider.svg";
import whiteFinger from "/public/fingerprint_white.svg";
import aadhaarLink from "/public/aadhaar_link.svg";
import lighting from "/public/light.svg";
import webCam from "/public/webcam.svg";
import hatGlass from "/public/hatGlass.svg";
import picture from "/public/picture.svg";
import kotakLife from "/public/kotak_bank.svg";
import virus from "/public/covid_icon.svg";
import congratulations from "/public/ic-congratulations.svg";
import donwloadApp from "/public/download_google.svg";
import moneyPouch from "/public/money_pouch.svg";
import secureLoan from "/public/secure_loan.svg";
import IplLogo from "/public/Ipl_logo.png";
import starImg from "/public/starimg.png";
import congratsBanner from "/public/congrats_banner.png";
import polling from "/public/polling_animation.gif";
import unlockLoan from "/public/Banner_button.gif";
import seperatorLine from "/public/seperator_line_icon.svg";
import uncheckTickIcon from "/public/uncheck_tick_icon.svg";
import greenTickIcon from "/public/green_tick_icon.svg";
import moneyBagIcon from "/public/money.svg";
import bankIcon from "/public/bank_icon.svg";
import infoIconDarkOrange from "/public/info_icon_dark_orange.svg";
import redCrossIcon from "/public/red_cross_icon.svg";
import callerIcon from "/public/caller_icon.svg";
import callerModalIcon from "/public/caller_modal_icon.svg";
import exclamationMarkIcon from "/public/exclamation_mark.svg";

export const webColors = {
  primary: "#174035",
  secondary: "#47EE40",
  pale: "#EDFFE8",
  orange: "#FA4616",
  fadeColor: "#989898",
  black: "#000",
  blue: "#407BEF",
  textFadeColor: "rgba(0, 0, 0, 0.38)",
  limeGreen: "#009640",
};

//SVG IMAGES
export const SvgImages = {
  bank,
  person,
  fingerprint,
  verticalDivider,
  shield,
  leftCurveArrow,
  rightCurveArrow,
  questionMark,
  brandLogo,
  homeLocation,
  infoIcon,
  appreciation,
  downArrow,
  upArrow,
  whiteFinger,
  whiteBank,
  bankDivider,
  aadhaarLink,
  goldCoins,
  lighting,
  hatGlass,
  webCam,
  kotakLife,
  virus,
  picture,
  donwloadApp,
  moneyPouch,
  secureLoan,
  congratulations,
  IplLogo,
  starImg,
  congratsBanner,
  seperatorLine,
  uncheckTickIcon,
  greenTickIcon,
  moneyBagIcon,
  bankIcon,
  infoIconDarkOrange,
  redCrossIcon,
  callerIcon,
  callerModalIcon,
  exclamationMarkIcon,
};

//GIFS
export const GIFS = {
  polling,
  unlockLoan,
};

//FACE VERIFICATION INSTRUCTIONS
export const FaceVerificationSteps = [
  {
    text: "Find a place with proper lighting.",
    icon: SvgImages.lighting,
    borderRequired: true,
  },
  {
    text: "Ensure you are not wearing any glasses or hat/cap.",
    icon: SvgImages.hatGlass,
    borderRequired: true,
  },
  {
    text: "Keep the webcam in front of you.",
    icon: SvgImages.webCam,
    borderRequired: true,
  },
  {
    text: "Ensure, the selfie should not be a photo of an existing photo.",
    icon: SvgImages.picture,
    borderRequired: false,
  },
];

//LOAN SANCTION DISPLAY DATA
export const LoanSanctionSteps = [
  {
    text: "Personal Details",
    subText: "Provide your Basic Personal Details.",
    icon: SvgImages.person,
  },
  {
    text: "Banking & KYC",
    subText:
      "Provide your Banking Details & Complete your KYC verification with your selfie.",
    icon: SvgImages.bank,
  },
  {
    text: "Repayment & E-Sign",
    subText: "Mandate & Sign your loan document Digitally",
    icon: SvgImages.fingerprint,
  },
];
//Bank Verification instructions
export const BankVerificationSteps = [
  {
    text: "Select Bank",
    icon: SvgImages.whiteBank,
  },
  {
    text: "Verify Bank Statement via Net Banking",
    icon: SvgImages.whiteFinger,
  },
];

//Gender
export const GendersMenu = [
  { name: "Male", value: "MALE" },
  { name: "Female", value: "FEMALE" },
];

export const currencyIcon = "₹";
//Number Formats
export const CurrencyFormat = (amount) => {
  var x = Number(amount);
  x = x.toString();
  var lastThree = x.substring(x.length - 3);
  var otherNumbers = x.substring(0, x.length - 3);
  if (otherNumbers != "") lastThree = "," + lastThree;
  var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
  return `${currencyIcon} ${res}`;
};

//MASK
export const MaskStringUptoLastChars = (str) => {
  if (str.length <= 4) {
    return str;
  } else {
    const maskedPart = "*".repeat(str.length - 4);
    const lastPart = str.slice(-4);
    return maskedPart + lastPart;
  }
};

//REGULAR EXPRESSIONS
//Mobile Regex
export const MOBILE_REGEX = (value) => {
  const regex = /^[0-9\b]+$/;
  if (value.length <= 10) return value === "" || regex.test(value);
};
//AADHAR Regex
export const AADHAR_REGEX = (value) => {
  const regex = /^[0-9\b]+$/;
  if (value.length <= 12) return value === "" || regex.test(value);
};
//Name Regex
export const NAME_REGEX = (value) => {
  const regex = /^[a-zA-Z\b]+$/;
  if (value.length <= 25) return value === "" || regex.test(value);
};
//EMAIL Regex
export const EMAIL_REGEX = (value) => {
  // const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/;
  const regex = /^[[0-9a-zA-Z@.\b]+$/;
  return value === "" || regex.test(value);
};
//Remarks Regex
export const FREE_TEXT_REGEX = (value) => {
  const regex = /^[a-zA-Z0-9 \s!@#$%^&*()_+={}[\]:;,.?~\\/-\s]*$/;
  return value === "" || regex.test(value);
};
//PAN Regex
export const PAN_REGEX = (value) => {
  const regex =
    /^([A-Z]([A-Z]([A-Z]([A-Z]([A-Z]([0-9]([0-9]([0-9]([0-9]([A-Z])?)?)?)?)?)?)?)?)?)?$/;
  return value === "" || regex.test(value);
};
//Password Regex
export const PASSWORD_REGEX = (value) => {
  const passwordRegex = /^[a-zA-Z0-9\s!@#$%^&*()_+={}[\]:;,.?~\\/-]+$/;
  return value === "" || passwordRegex.test(value);
};

//Number Regex
export const NUMBER_REGEX = (value) => {
  const regex = /^[0-9\b]+$/;
  return value === "" || regex.test(value);
};

export const NAME_REGEX_WITH_SPACE = (value) => {
  // Check if the value starts with a space
  if (value.startsWith(' ')) {
    return false;
  }

  const regex = /^[a-zA-Z\s]+$/;
  if (value.length <= 25) return value === "" || regex.test(value);
};

export const PAN_CARD_REGEX = /^[A-Z0-9]*$/;

export const relationshipWithNominee = [
  {
    value: "Mother",
    label: "Mother",
  },
  {
    value: "Father",
    label: "Father",
  },
  {
    value: "Brother",
    label: "Brother",
  },
  {
    value: "Sister",
    label: "Sister",
  },
  {
    label: "Spouse",
    value: "Spouse",
  },
];

export const gender = [
  {
    value: "male",
    label: "Male",
  },
  {
    value: "female",
    label: "Female",
  },
  {
    value: "others",
    label: "Others",
  },
];

export const errorMessageText = "Something went wrong!";

export const lplStageNames = {
  FLOW_STARTED: "Flow_Started",
  ADDRESS_CONFIRMATION: "address_conformation",
  DIGIKYC: "digikyc",
  DIGIKYC_FULL: "digikyc_full",
  PAN_VERIFICATION: "pan_verification",
  BANK_ACCOUNT_DETAISLS: "bank_account_details",
  CHANGE_BANK_DETAILS: "change_bank_details",
  INSURANCE_DETAILS: "insurance_details",
  NOMINEE_DETAILS: "nominee_details",
  CREATE_MANDATE: "create_mandate",
  MANDATE_STATUS_CHECK: "mandate_status_check",
  ESIGN: "e_sign",
  CONGRATULATION: "congratulation",
}

export const GeneralInsuranceTermsConditions = `
  We should be given immediate written notice of any event that may give rise to a claim under the Policy, in accordance with the claims procedure under the Policy.\n
  All claims made under the Policy will be subject to the applicable deductible, any sub-limits and the availability of the Sum Insured.\n
  The Hospitalization is for Medically Necessary Treatment and is commenced and continued on the written advice of the treating Medical Practitioner\n
  Critical Illness covered –\n
`;

export const LifeInsuranceTermsConditions = `
  I understand agree and confirm that these statements and this declaration are basis of the contract between the insurer and the policyholder. Subject to Section 45 of the Insurance Act 1938 as amended from time to time, if any untrue statements are contained herein or there has been any non-disclosure of any material fact, the policy to be issued by the insurer in the name of the policyholder may be treated as void as far as I am concerned.\n
  I confirm that I have read and understood the rules and any additional rules of the plan, the standard policy provisions and any additional provisions that govern the policy to be issued by insurer in the name of the policyholder and on my life and I agree and confirm that the same shall be binding on me.\n
  I authorise the policyholder to disclose to the insurer such particulars as they may require including the details given above and any changes to the same, pay the premium payable on my behalf /collected from me to the insurer.\n
  I understand that any statutory levy or charges including any indirect tax may be charged to me either now or in future by the insurer and I agree to pay the same.\n
  I understand that HDFC Life Insurance Company Limited (HDFC Life) has the right to reject a proposal without giving reasons thereto and confirm to give an undertaking that I shall not raise any claims thereof.\n
  I understand the significance of the contract will be governed by the provisions of the Insurance act 1938 as amended from time to time and that the same will not commence until written acceptance of this application issued by the insurer on its normal terms and conditions is received.\n
  I further agree that if after the date of submission of the proposal but before the issuance of Policy (i) there is an adverse change in my occupation, financial condition, health condition, which will affect the decision of the Company in underwriting risk or (ii) if the proposal for assurance or an application for revival of the policy made to any insurer on my life or the Life to be assured is withdrawn or dropped, deferred, declined or accepted on terms other than as proposed, I shall forthwith intimate the same to the company in writing and failure to do so shall lead to a decision as per the applicable terms and conditions of the policy\n
  I hereby declare that the content of the form and document has been fully explained to me and I have fully understood the significance of the proposed contract.\n
  I understand and agree that in case any error, omission, incorrect information, blank forms, etc. are detected at the claim stage because of lapses on the part of the scheme member, such claims shall be treated as invalid by the insurer and the claim pay-out shall not be processed\n
  I understand that any If any of the requirements raised by the insurer including further requirements pertaining to medical tests/reports/investigations are not completed within three months from the date when the member enrollment details were shared by the Master Policy Holder (MPH) with the insurer, the received premium amount for the said enrollment, would be refunded and the respective member's enrollment shall be deemed as withdrawn.\n
  I declare and hereby consent and authorize the Company or any of its authorized representatives to seek medical information from any doctor or from a hospital who at any time has attended me or from any past or present employer concerning anything which affects my physical or mental health and seeking information from any insurance company to which an application for insurance has been made for the purpose of underwriting the proposal and for claim settlement\n
  In an event of change in age from the declared age before the Risk Commence Date, the Sum Assured may be revised to adjust for the possible premium shortfall\n
  I acknowledge that, in conjunction with obtaining the loan from the Master Policyholder, i am taking an insurance cover from HDFC Life to safeguard myself against a loan default on the occurrence of any contingent event as covered under the COI issued to me. Further, i hereby grant explicit consent to the MPH for including the insurance premium amount payable by me in the loan EMI (subject to confirmation by MPH) and paying it directly to the Insurance company\n
`;

export const generalInsurancePoints = [
  "Cancer of Specified Severity",
  "Kidney Failure Requiring Regular Dialysis",
  "Multiple Sclerosis with Persisting Symptoms",
  "Major Organ / Bone Marrow Transplant",
  "Open Heart Replacement or Repair of Heart Valves",
  "Open Chest CABG",
  "Permanent Paralysis of Limbs",
  "Myocardial Infarction (First Heart Attack – of Specific Severity)",
  "Stroke Resulting in Permanent Symptoms",
  "Benign Brain Tumor",
  "Parkinson’s Disease",
  "Coma of Specified Severity",
  "End Stage Liver Failure",
  "Alzheimer’s Disease",
  "Aorta Graft Surgery",
  "Major Burns",
  "Loss of Hearing (Deafness)",
  "Loss of Speech",
  "Loss of Vision (Blindness)",
  "Motor Neurone Disease with Permanent Symptoms",
  "Loss of Limbs",
  "Aplastic Anaemia",
  "End Stage Lung Failure",
  "Primary (Idiopathic) Pulmonary Hypertension",
  "Bacterial Meningitis",
  "Apallic Syndrome or Persistent Vegetative State (PVS)",
  "Coronary Angioplasty (PTCA)",
  "Encephalitis",
  "Fulminant Hepatitis",
  "Chronic Relapsing Pancreatitis",
  "Major Head Trauma",
  "Medullary Cystic Disease",
  "Muscular Dystrophy",
  "Poliomyelitis",
  "Systemic Lupus Erythematous",
  "Brain Surgery"
];
